.treatments-list-container .treatments-types-button {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    padding: 20px 80px 0px 80px;
}

.treatments-list-container .treatments-types-button button,
.link {
    all: unset;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    background-color: #CAAAB7;
    border-radius: 15px;
    min-width: 16%;
    text-align: center;
    transition: 0.3s;
    align-content: center;
}

.treatments-list-container .treatments-types-button .active {
    background-color: #A5617C !important;
    transition: 0.3s;
}

.treatments-list-container .treatment-item {
    display: flex;
    padding: 60px 100px 0px 100px;
    min-height: 615px;
}


.treatments-list-container .treatment-item .treatment-slider-container {
    border-radius: 10px;
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: auto;
}

.treatment-slider-container .treatment-slider {
    display: flex;
    height: 100%;
    overflow: hidden;
    overflow-x: scroll;
    scroll-behavior:unset;
    overscroll-behavior-x: contain;
    scroll-snap-type: x proximity;
    &::-webkit-scrollbar {
        display: none;
    }
}

.treatment-slider-buttons-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.btn-slider{
    all: unset;
    padding: 5px 10px;
    color: #fff;
    border-radius: 50%;
    background-color: #CAAAB7;
    cursor: pointer;
    transition: 0.3s;
}

.btn-slider:hover{
    background-color: #A5617C;
    transition: 0.3s;
}

.treatment-slider img {
    border-radius: 10px;
    min-width: 100% !important;
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: 0.4s;
}

.treatment-item .treatment-item-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 80px;
    gap: 1rem;
    word-break: break-word;
}

.treatment-item .treatment-item-content h2 {
    text-transform: uppercase;
}

.treatment-item .treatment-item-content li {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-top: 2.5px;
}

.treatment-item .treatment-item-content li .icon-li {
    background-color: #A5617C;
    height: 5px;
    width: 5px;
    margin-right: 10px;
    display: inline-block;
    border-radius: 50%;
    align-self: flex-start;
    margin-top: 8px;
}

.treatment-item .treatment-item-content li p {
    width: 100%;
    margin: 0;
}

.btn-volver{
position: fixed;
z-index: 9999;
bottom: 0%;
margin-right: 10px !important;
}

.btn-volver span{
    width: 15px !important;
    background-image: url("../../Assets/Icons/left-arrow.svg") !important;
    margin-right: 15px !important;
}