.popUp-container {
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(61 61 61 / 40%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: auto;
}

.popUp-container .popUp {
    background-color: #fff;
    border-radius: 25px;
    width: 800px;
    margin: auto;
}

.popUp-container .popUp.popUpAfeccionesTratamientos {
    padding: 35px 50px;
    position: relative;
}

.popUpAfeccionesTratamientos .popUpAfeccionesTratamientos-title {
    color: #373737;
    font-size: 25px;
}

.popUpAfeccionesTratamientos .popUpAfeccionesTratamientos-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 230px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 5px;
      }
    
      /* Track */
      &::-webkit-scrollbar-track {
        background: #F3E7EC;
      }
    
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #CAAAB7;
      }
    
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #A5617C;
      }
}

.popUpAfeccionesTratamientos-content .tratamiento {
    width: 330px;
    height: 160px;
    border-radius: 25px;
    position: relative;
    margin-top: 20px;
    display: flex;
    cursor: pointer;
    background-color: #9e6f82;
}

.popUpAfeccionesTratamientos-content .tratamiento img {
    object-fit: cover;
    position: absolute;
    width: 330px;
    height: 160px;
    opacity: 0.5;
    transition: .6s;
    border-radius: 25px;
}

.popUpAfeccionesTratamientos-content .tratamiento h4 {
    position: relative;
    align-self: center;
    margin: auto;
    color: #fff;
    text-transform: uppercase;
    padding: 20px;
    text-align: center;
    width: 100%;
    font-size: 20px;
    letter-spacing: 1px;
}

.popUp.popUpAfeccionesTratamientos .btn-turno {
    all: unset;
    display: block;
    background-color: #a5617c;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    letter-spacing: 1px;
    margin: 30px auto 0 !important;
    padding: 15px 30px;
    width: fit-content;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 600;
}

.popUp.popUpAfeccionesTratamientos .btn-turno:hover {
    background-color: #79465a;
}

.popUp-container .popUp.popUpAfeccionesTratamientos .navbar-close-btn {
    display: block;
    opacity: 1 !important;
    position: absolute;
    background-image: url('/src/Assets/Icons/close.svg');  
    right: 0;
    top: 0;
}

@media only screen and (max-width: 480px) {
    .popUp-container .popUp.popUpAfeccionesTratamientos {
        width: 365px !important;
        padding: 26px !important;
    }

    .popUpAfeccionesTratamientos h3.popUpAfeccionesTratamientos-title {
        font-size: 19px !important;
    }

    .popUp.popUpAfeccionesTratamientos .btn-turno {
        font-size: 18px;
        padding: 10px 20px;
    }

    .popUpAfeccionesTratamientos .popUpAfeccionesTratamientos-content {
        height: 60vh !important;
    }
}