@media only screen and (max-width: 480px) {
    .treatments-item-container {
        min-height: 225px !important;
    }

    .about-background-image img {
        object-position: 0px -60px !important;
    }

    .home-banner-image-title h1 {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .home-banner-image-background {
        background-position-y: 50% !important;
    }

    .home-banner-text h2 {
        font-size: 17px !important;
        padding: 15px 5px 15px !important;
        margin: 0 20px !important;
    } 

    .home-banner-text p {
        padding: 0 15px !important;
        font-size: 14px !important;
    }

    .afecciones-container .afecciones-title-container{
        margin: 0 20px 20px !important;
    }

    .afecciones-container .afecciones-title-container {
        font-size: 25px !important;
    }


    .afecciones-carrousel .afecciones-carrousel--item h3 {
        font-size: 23px !important;
    }

    .message--container .message {
        font-size: 23px !important;
    }

    .message--container .btn-contact {
        margin: 0 !important;
    }

    a.whatsapp--link  {
        height: 50px;
        width: 50px;
    }
}