

@media only screen and (max-width: 480px) {
    /* .treatments-types-button{
        flex-wrap: unset;
        overflow: auto;
        overflow-x: scroll;
    } */

    .treatments-list-container .treatments-types-button .link{
        flex: 1 0 41% !important;
        /* flex: 1 0 70% !important; */
    }

    .treatments-list-container .treatment-item{
        padding: 20px 50px 0px 50px;
        height: auto;

    }
    .treatment-item .treatment-item-content{
        padding: 10px 0;
    }

    .treatments-list-container .treatment-item img{
        width: 100% !important;
    }
}