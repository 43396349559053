.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-top: 100px;
}

.contact-title {
    border-width: 1px 0px;
    border-color: #A5617C;
    border-style: solid;
    line-height: 60px;
    letter-spacing: 0.1em;
    font-size: 2rem;
    color: #A5617C;
}

.contact-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-data {
    width: 40%;
    padding: 0 60px;
}



.contact-data .contact-data-item {
    margin-bottom: 15px;
    color: #A5617C;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.contact-data-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.i-map {
    background-image: url("../../Assets/Icons/map.svg");
}

.i-phone {
    background-image: url("../../Assets/Icons/phone.svg");
}

.i-whatsapp {
    background-image: url("../../Assets/Icons/whatsapp.svg");
}

.i-instagram {
    background-image: url("../../Assets/Icons/instagram.svg");
}


/* -- CONTACT FORM -- */

.contact-form-container {
    border-left: 2px solid #A5617C;
    width: 60%;
    margin: 20px auto;
    padding: 0 50px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 5px 0;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label-form {
    font-weight: 700;
    color: #A5617C;
    font-size: 1.1rem;
}

.input-form {
    all: unset;
    border: 1px solid #A5617C;
    padding: 5px 10px;
    border-radius: 5px;
    color: #616161;
    word-break: break-word;
}

.btn-submit-form {
    margin: 20px 0 !important;
    all: unset;
    padding: 5px 15px;
    background-color: #A5617C;
    border-radius: 25px;
    letter-spacing: 1px;
    cursor: pointer;
    align-self: flex-end;
    color: #fff;
    display: flex;
    gap: 5px;
}

.contact-btn-submit-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../Assets/Icons/arrow-right.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}


.h-100px {
    height: 100px;
}

.select {
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    text-transform: uppercase;
}

.validation {
    color: #a5617c;
    margin-top: 4px;
}