* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Dm Sans', sans-serif !important;
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
  height: auto;
}

:root {
  --grisOscuro: #373737;
}

.home-banner-image-background {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background: linear-gradient(0deg, rgba(179, 111, 138, 0.534), rgb(246 212 226 / 57%)), url('/src/Assets/images/Vanity/background-home-desktop.jpg');
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


a {
  all: unset;
  cursor: pointer;
}

/*MODIFICADO NUEVA VERSION*/
nav {
  position: fixed;
  background-color: #FFF;
  width: 100%;
  min-width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 55px;
  z-index: 99999;
  box-shadow: 0px 8px 40px 6px rgba(0, 0, 0, 0.062);
  transition: 1s;
}

.overflow-h {
  overflow: hidden;
}

.nav-logo-container {
  max-width: 45px !important;
  margin-left: 3rem;
  transition: 1s;
}

.dropdown-tratamientos--container {
  position: relative;
  display: inline-block;
}

.dropdown-tratamientos--container .dropdown-tratamientos {
  display: none;
  position: absolute;
  background-color: #fff;
  top: 18px;
  list-style-type: none;
  padding: 12px 12px 12px;
  border-radius: 0 0 12px 12px;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.062);
  z-index: 999;
  width: 210px;
}

.dropdown-tratamientos--container:hover .dropdown-tratamientos {
  display: flex;
}

.dropdown-tratamientos--container .dropdown-tratamientos li {
  text-decoration: none;
  color: #A5617C;
  font-size: 0.9rem;
  font-weight: 500;
}
/* 
.dropdown-tratamientos--container .dropdown-tratamientos li a:hover {
  font-weight: 600;
} */

.dropdown-tratamientos--container .dropdown-tratamientos li a {
  font-size: 0.9rem;
}

.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: #A5617C;
  font-size: 1rem;
  font-weight: 500;
  width: 50%;
  position: relative;
  padding-bottom: 3px;
}

.navbar-links-container a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: 1;
  border-bottom: 2px solid #A5617C;
  transition: 1.5s;
}

.navbar-links-container a:hover::before {
  width: 100%;
  transition: 1.5s;
}

.active-link {
  font-weight: bold;
}

.navbar-btn {
  all: unset;
  cursor: pointer;
  height: 30px;
  width: 30px;
  background-image: url('/src/Assets/Icons/navbar-icon.svg');
  display: none;
  opacity: 0;
  margin: 10px;
  background-repeat: no-repeat;
}

.home-banner-container {
  display: flex;
  flex-direction: column;
}


/*MODIFICADO NUEVA VERSION*/
.home-banner-image-background-container {
  position: relative;
  width: 100%;
  /* height: 560px; */
  height: 100vh;
  /* background-color: #F3E7EC; */
  /* background: linear-gradient(0deg, rgb(165 97 124), rgba(165, 97, 124, 0.4)); */
  /* background: linear-gradient(0deg, rgb(179 111 138), rgb(246 212 226 / 57%)); */
  /*AGREGADO*/
  /* padding: 80px; */
}

.home-banner-image-background-container video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.home-banner-image-title {
  color: #000;
  position: absolute;
  bottom: 0;
  padding: 50px 80px;
  width: 750px;
  box-sizing: content-box;
}

.home-banner-image-title h1 {
  font-size: 58px;
  line-height: 58px;
  font-weight: 600;
  margin: 0;
}

/* SACAR SI NO SE APRUEBA */
.home-banner-image-title.change {
  color: #000;
  position: absolute;
  bottom: 0;
  padding: 50px;
  width: 750px;
  box-sizing: content-box;
  margin: auto;
  left: 0;
  right: 0;
}

.home-banner-image-title.change h1 {
  font-size: 58px;
  line-height: 58px;
  font-weight: 600;
  margin: 0;
  color: #fff;
  text-align: center;
}


.home-banner-image-title h2 {
  width: 100%;
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  letter-spacing: 5px;
  line-height: 2rem;
}

.home-banner-text {
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--grisOscuro);
}

.home-banner-text h2 {
  max-width: 800px;
  font-size: 33px;
  border-top: 1px solid #CAAAB7;
  padding: 30px 0px 15px;
  text-align: center;
}

.home-banner-text p {
  font-size: 25px;
  text-align: center;
}

.home-banner-text .btn-contact {
  align-self: center;
  text-transform: uppercase;
  margin: 20px 0 50px;
}

.homer-banner-btn {
  all: unset;
  padding: 15px 19px;
  background: #A5617C;
  border-radius: 25px;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: #fff;
  transition: .3s;
  font-weight: bold;
  display: table;
  margin: 40px auto;
}

.btn-contact--icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../Assets/Icons/arrow-down.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  filter: invert(1);
  display: table-cell;
  vertical-align: middle;
}

/* SECTION ABOUT */

.about-container {
  display: flex;
  height: 600px;
  padding-top: 55px;
}


.about-background-image {
  background-color: #F3E7EC;
  width: 50%;
  padding: 40px 80px;
}

.about-background-image img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.about-presentation {
  background-color: #A5617C;
  width: 50%;
  color: #FFF;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.about-title {
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 0.1em;
  border-width: 1px 0px;
  border-color: #fff;
  border-style: solid;
  padding: 10px 0px;
  width: 70%;
  align-self: center;
  text-align: center;
}

.about-paragraph {
  padding: 40px 0px 0px 0px;
  font-weight: 400;
  text-align: center;
  line-height: 22px;
}

.btn-contact {
  all: unset;
  padding: 15px 19px;
  background: #CAAAB7;
  border-radius: 25px;
  letter-spacing: 0.5px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: auto;
  color: #fff;
  transition: .3s;
  font-weight: bold;
  font-weight: bold;
  display: block;
  width: fit-content;
}

.btn-contact:hover {
  transition: .3s;
  background: #e7bfc7;
}



.banner-container {
  display: flex;
  flex-direction: row;
}

.box-item {
  padding: 0 90px;
  padding-top: 60px;
  text-align: center;
  width: 50%;
  color: #616161;
}

.box-item img {
  width: 55px;
}

.box-item h2 {
  margin: 3px 0px 9px 0px;
}

.box-item p {
  font-size: 0.85rem;
}

.treatments-container {
  padding-top: 55px;
}

.treatments-banner {
  height: 320px;
  /* background-attachment: fixed; */
  background: linear-gradient(0deg, rgba(165, 97, 124, 0.594), rgba(165, 97, 124, 0.639)), url('/src/Assets/images/Vanity/DSC_0430.jpg');
  background-size: cover;
  background-position-y: 40%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  padding: 50px 400px 50px 80px;
}

.treatments-title {
  border-width: 1px 0px;
  border-color: #fff;
  border-style: solid;
  line-height: 60px;
  letter-spacing: 0.1em;
  font-size: 2rem;
}

.treatments-description {
  padding: 10px 0px;
  font-size: 18px;
}

/**/

.item-container {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.item-container .btn-contact {
  align-self: flex-start !important;
}

.item-container .btn-contact:hover,
.home-banner-text .btn-contact:hover {
  background-color: #A5617C;
}

.treatments-item-container {
  border-radius: 15px;
  padding: 40px;
  display: flex;
  flex-direction: row;
  margin: 40px 80px;
  box-shadow: 0px 8px 41px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100%;
  min-height: 300px;
}


.treatments-item-container .title-item-container {
  color: #373737;
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 1.8rem;
}

.description-item-container {
  color: #616161;
  text-align: left;
  padding-bottom: 20px;
  font-size: 18px;
}

.footer {
  height: 120px;
  background: rgb(224, 196, 208);
  background: linear-gradient(0deg, rgba(224, 196, 208, 1) 0%, rgba(255, 255, 255, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #616161;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer .sections-list {
  list-style: none;
  display: flex;
  gap: 30px;
}

.footer span {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-estetica {
  background-image: url('../Assets/Logo.png');
}

.icon-instagram {
  background-image: url('../Assets/Icons/instagram-footer.svg');
}

.icon-whatsapp {
  background-image: url('../Assets/Icons/whatsapp-footer.svg');
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 2s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

a.whatsapp--link {
  all: unset;
  position: fixed !important;
  display: block !important;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  height: 60px;
  width: 60px;
  background-image: url('../Assets/images/whatsapp.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: .2s;
  z-index: 9999;
}

a.whatsapp--link:hover {
  transition: .2s;
  transform: scale(1.1)
}