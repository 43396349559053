@media only screen and (max-width: 768px){

    .treatments-list-container .treatment-item .treatment-slider-container{
        height: 200px;
    }
    .treatments-list-container .treatment-item .treatment-slider-container, .treatment-slider-container .treatment-slider{
        width: 100%;
        border-radius: 10px;
    }

    .treatments-list-container .treatments-types-button{
        flex-wrap: wrap;
        padding: 0px 50px ;
    }
    .treatments-list-container .treatments-types-button .link{
        flex: 1 0 31%;
        margin: 5px;
    }

    .treatments-list-container .treatment-item{
        flex-direction: column;
        padding: 20px 50px 0px 50px;
        height: auto;
    }

    .treatments-list-container .treatment-item img{
        width: 50%;
        height: auto;
        border-radius: 0 !important;
    }

    .treatment-item .treatment-item-content{
        padding: 40px 0;
        width: 100%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1075px){
    .treatments-list-container .treatments-types-button{
        flex-wrap: wrap;
    }
    .treatments-list-container .treatments-types-button .link{
        flex: 1 0 31%;
        margin: 5px;
    }
    .treatments-list-container .treatment-item{
        flex-direction: column;
        padding: 40px 80px 0px 80px;
        height: auto;
    }

    .treatments-list-container .treatment-item .treatment-slider-container{
        height: 300px;
    }
    .treatments-list-container .treatment-item .treatment-slider-container, .treatment-slider-container .treatment-slider{
        width: 100%;
        border-radius: 10px;
    }

    .treatments-list-container .treatment-item img{
        width: 50%;
        height: auto;
        border-radius: 0 !important;
    }

    .treatment-item .treatment-item-content{
        padding: 40px 0;
        width: 100%;
    }
}