@media only screen and (max-width: 768px) {

    .home-banner-image-background {
        background: linear-gradient(0deg, rgba(179, 111, 138, 0.534), rgb(246 212 226 / 57%)), url('/src/Assets/images/Vanity/background-home-mobile.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .about-title {
        font-size: 20px;
    }

    .about-paragraph {
        padding: 20px 0px 0px 0px;
        font-size: 13px;
    }

    .nav-logo-container{
        margin: 0 10px;
    }

    .navbar-links-container {
        display: none;
    } 
    .navbar-btn{
        display: block;
        opacity: 1 !important;
    }

    .navbar-close-btn{
        background-image: url('/src/Assets/Icons/close.svg');     
        right: 0;
        top: 0;
    }

    .responsive-nav .nav-logo-container{
        left: 0;
        top: 4.5px
    }

    .responsive-nav .nav-logo-container, .navbar-close-btn{
        position: absolute;      
    }

    .responsive-nav{
        transition: 1s;
        height: 100%;
    }

    .responsive-nav .navbar-menu-btn{
        display: none;
    }

    .responsive-nav .navbar-links-container {
        width: 100%;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        gap: 1rem;
        position: relative;
        height: 100%;
        transition: 1s;
        padding: 0 50px;
    }

    .responsive-nav .navbar-links-container a{
        font-size: 1.2rem !important;
        margin-right: 0 !important;
    }

    .dropdown-tratamientos--container .dropdown-tratamientos {
        position: unset;
        display: flex;
        width: 100%;
        box-shadow: none;
        list-style: inside;
        padding: 12px 12px 0;
    }

    .home-banner-image-title {
        width: 100% !important;
        padding: 60px 30px;
        box-sizing: border-box !important;
    }

    .home-banner-image-title h2 {
        font-size: 1rem;
        width: 100%;
    }

    .home-banner-text h2 {
        max-width: none;
        font-size: 30px;
        margin: 0 20px;
    }

    .home-banner-text p {
        font-size: 22px;
    }

    .about-container {
        height: 100%;
        flex-direction: column;
    }

    .about-background-image {
        width: 100%;
        height: 100px;
        padding: 0;
    }

    .about-background-image img {
        border-radius: 0px !important;
        object-position: 0px -75px;
    }

    .about-presentation {
        width: 100%;
    }

    .btn-contact {
        margin-top: 20px;
        padding: 10px 10px;
        font-size: 13px;
    }
    
        /* - BANNER MISION VISION - */
        .banner-container {
            flex-direction: column;
        }
    
        .box-item {
            width: 100%;
            padding: 0 45px;
            padding-top: 60px;
        }
    
        .box-item img {
            width: 40px;
        }
    
        .box-item h2 {
            margin: 2px 0px 9px 0px;
            font-size: 20px;
        }
    
        .box-item p {
            font-size: 14px;
        }
    
    
        .services-banner {
            padding: 10px;
        }
    
        .services-title {
            line-height: 40px;
            font-size: 22px;
        }
    
        .services-description {
            font-size: 15px;
        }
    
/*treatments*/    

    .treatments-item-container {
        margin: 30px 20px;
        padding: 30px;
        box-shadow: 0px 8px 30px 1px rgba(0, 0, 0, 0.1);
        min-height: 200px;
    }

    .treatments-item-container .title-item-container{
        margin-bottom: 10px;
        font-size: 25px;
    }

    .description-item-container{
        font-size: 15px;
    }

    .treatments-banner{
        padding: 10px;
    }
    .treatments-description {
        font-size: 15px;
    }

    .treatments-item-container .btn-contact {
        position: absolute;
        bottom: 10px;
        right: 15px;
    }

    .treatments-carrousel-container {
        visibility: hidden;
    }

    .item-container {
        width: 100%;
    }

    /*FORM SECTION*/
    
    .contact-container {
        padding-top: 60px !important;
    }

    .contact-body {
        flex-direction: column;
    }

    .contact-data {
        width: 100% !important;
    }

    .contact-form-container {
        width: 100% !important;
        border: none !important;
        padding: 0 20px !important;
    }

    .row {
        flex-direction: column;
    }

    .column {
        width: 100%;
    }

    .footer {
        flex-direction: column;
    }

    .footer .sections-list {
        gap: 15px;
    }
    
    .home-banner-image-background-container {
        height: 100%;
        padding-top: 55px;
    }

    .home-banner-image-background-container video {
        height: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1075px){
    .about-title {
        font-size: 20px;
    }

    .about-paragraph {
        padding: 20px 0px 0px 0px;
        font-size: 12px;
    }

    .btn-contact {
        margin-top: 10px;
        padding: 11px;
        font-size: 14px;
    }
} 