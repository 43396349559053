
/* -- CARROUSEL -- */

.treatments-carrousel-container {
    padding: 0;
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    overscroll-behavior-x: contain;
    scroll-snap-type: x proximity;
 

    z-index: 9;
    width: 55%;
    height: auto;
    position: absolute;
    top: 70px;
    left: 40%;
    margin-left: 20px;
    padding-bottom: 5px;
  
    /* width */
    &::-webkit-scrollbar {
   
      height:  5px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #F3E7EC;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #CAAAB7;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #A5617C;
    }
  }
  
  
  /* -- CARROUSEL - ITEM */
  
  .treatments-items-list {
    display: flex;
    margin-right: 25px;
  }
  
  .treatments-items-list .item-list {
    height: 180px;
    width: 350px;
    align-self: center;
    border-radius: 15px;  
    background-color: #9e828d;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: center;
    text-transform: uppercase;
  }
  
  .item-list img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    transition: .6s;
    opacity: 0.5;
    transition: .6s;
  }
  
  
  
   .treatments-items-list:hover img{
    opacity: 0.1;
    transition: .6s;
  } 
  
  .treatment-name{
    /* display: none; */
    transition: .6s;
    position: absolute;
    z-index: 999;
    color: #FFF;
    text-align: center;
    font-size: 1.8rem;
  }
  
  /* .treatments-items-list:hover .treatment-name{
    transition: .6s;
    display: block;
  } */
  